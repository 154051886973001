import 'css/dark_mode.scss'
document.getElementById('darkMode').addEventListener('click', function () {
	document.body.classList.toggle('dark')
	document.getElementById('darkMode').classList.toggle('off')

	let request = new XMLHttpRequest()

	request.open("POST", "/api/dark-mode")
	request.send()
})
